<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="1200px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
      ><el-form
        ref="airFormRef"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="150px"
      >
        <el-form-item label="会议名称" prop="name">
          <el-input
            v-model="airForm.name"
            placeholder="请输入会议名称"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input
            v-model="airForm.content"
            placeholder="请输入内容"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="会议编号" prop="meetingNo">
          <el-input
            v-model="airForm.meetingNo"
            placeholder="请输入会议编号"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="会议日期" prop="meetingDate">
          <el-date-picker
            v-model="airForm.meetingDate"
            type="datetime"
            placeholder="请输入会议日期"
            value-format="yyyy-MM-dd hh:mm:ss"
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="会议时间" prop="meetingTime">
          <el-date-picker
            v-model="airForm.meetingTime"
            type="datetime"
            placeholder="请输入会议时间"
            value-format="yyyy-MM-dd hh:mm:ss"
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="主持人姓名" prop="hostName">
          <el-input
            v-model="airForm.hostName"
            placeholder="请输入主持人姓名"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="主持人部门" prop="hostDept">
          <el-input
            v-model="airForm.hostDept"
            placeholder="请输入主持人部门"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="地点" prop="location">
          <el-input
            v-model="airForm.location"
            placeholder="请输入地点"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="开会方式" prop="way">
          <el-input
            v-model="airForm.way"
            placeholder="请输入开会方式"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-col :span="24" v-if="handleType === 'update'">
          <el-form-item label="内容记录">
            <el-button
              icon="el-icon-edit"
              size="small"
              v-if="!airForm.qualityAuditor"
              @click="handleAdd2"
            >
              记录
            </el-button>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="airForm.recordContent">
          <el-form-item label="会议记录内容" prop="recordContent">
            <el-input
              v-model="airForm.recordContent"
              placeholder="请输入会议记录内容"
              type="textarea"
              readonly
            />
          </el-form-item>
          <el-form-item label="会议记录目的" prop="purpose">
            <el-input
              v-model="airForm.purpose"
              placeholder="请输入会议记录目的"
              type="textarea"
              readonly
            />
          </el-form-item>
        </el-col>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit"> 确 定 </el-button>
      </div>
      <el-table :data="signList" style="width: 100%" v-if="handleType !== 'add'">
        <el-table-column prop="name" label="签到人员" align="center">
        </el-table-column>
        <el-table-column prop="signTime" label="签到时间" align="center">
        </el-table-column>
      </el-table>
      <section v-if="handleType != 'add'">
        <!-- <div slot="footer">
          <el-button @click="airDialogVisible = false">关闭</el-button>
        </div> -->
      </section>
      <el-dialog
        width="60%"
        title="记录会议"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body
      >
        <el-form
          ref="productFormRef"
          :model="airDetailForm"
          :rules="productFormRules"
          label-position="right"
          label-width="150px"
        >
          <el-form-item label="会议记录内容" prop="recordContent">
            <el-input
              v-model="airDetailForm.recordContent"
              placeholder="请输入会议记录内容"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="会议记录目的" prop="purpose">
            <el-input
              v-model="airDetailForm.purpose"
              placeholder="请输入会议记录目的"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="innerVisible = false"> 取 消 </el-button>
          <el-button type="primary" @click="productFormSubmit2">
            确 定
          </el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input
          v-model="searchForm.name"
          placeholder="请输入名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="airList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="会议名称" />
      <el-table-column prop="meetingNo" label="会议编号" />
      <el-table-column prop="meetingDate" label="会议日期" />
      <el-table-column prop="hostName" label="主持人姓名" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleSign(scope.row)"
          >
            签到
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      width="40%"
      title="新增签到"
      :visible.sync="innerVisible2"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form
        ref="productFormRef"
        :model="airForm"
        :rules="productFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="员工" prop="employeeId">
          <el-select
            v-model="airForm.employeeId"
            placeholder="请选择员工"
            @change="EmployeeChange"
            filterable
            remote
            clearable
            multiple
            :remote-method="getEmployee"
          >
            <el-option
              v-for="item in EmployeeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="innerVisible2 = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit3">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-pagination
      :total="airList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getMeetingList,
  deleteMeetingList,
  recordingMeetingList,
  signMeetingList,
  getSignMeetingList,
  addMeetingList,
  getMeetingDetail,
  updateMeetingDetail,
  getEmployeeList,
} from "@/api/humanManagement/humanManagement.js";

export default {
  data() {
    return {
      airDialogVisible: false,
      innerVisible: false,
      innerVisible2: false,
      airFormTitle: "",
      airForm: {},
      productPage: {
        list: [],
        total: 0,
      },
      airList: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: "",
      handleDetailType: "",
      runningTime: [],
      airDetailForm: {},
      productFormRules: {
        recordContent: [
          {
            required: true,
            message: "会议记录内容内容不能为空",
            trigger: ["blur", "change"],
          },
        ],
        purpose: [
          {
            required: true,
            message: "会议记录目的不能为空",
            trigger: ["blur", "change"],
          },
        ],
        employeeId: [
          {
            required: true,
            message: "签到人员不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      productFormRules2: {
        name: [
          {
            required: true,
            message: "会议名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
        content: [
          {
            required: true,
            message: "内容不能为空",
            trigger: ["blur", "change"],
          },
        ],
        meetingNo: [
          {
            required: true,
            message: "会议编号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        meetingDate: [
          {
            required: true,
            message: "会议日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        meetingTime: [
          {
            required: true,
            message: "会议时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
        hostName: [
          {
            required: true,
            message: "主持人姓名不能为空",
            trigger: ["blur", "change"],
          },
        ],
        hostDept: [
          {
            required: true,
            message: "主持人部门不能为空",
            trigger: ["blur", "change"],
          },
        ],
        location: [
          {
            required: true,
            message: "地点不能为空",
            trigger: ["blur", "change"],
          },
        ],
        way: [
          {
            required: true,
            message: "开会方式不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      EmployeeList: [],
      signList: [],
    };
  },
  async created() {
    let params = {
      pageNum: 1,
      pageSize: 10,
    };
    let airList = await getMeetingList(params);
    this.airList = airList;
  },
  methods: {
    async getEmployee(name) {
      if (name) {
        let params2 = {
          pageNum: 1,
          pageSize: 100,
          name,
        };
        let EmployeeList = await getEmployeeList(params2);
        this.EmployeeList = EmployeeList.list;
      } else {
        this.EmployeeList = [];
      }
    },
    async getSignList(id) {
      let params2 = {
        id,
      };
      let signList = await getSignMeetingList(params2);
      this.signList = signList;
    },
    airDialogClose() {
      this.$refs.airFormRef.resetFields();
    },
    productFormSubmit() {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === "add") {
            console.log(this.airForm, "this.airForm");
            await addMeetingList(this.airForm);
          } else if (this.handleType === "update") {
            let params = this.airForm;
            params.reviewDetailJson = JSON.stringify(params.detailList);
            delete params.detailList;
            delete params.workshopAuditor;
            delete params.workshopReviewTime;
            delete params.qualityAuditor;
            delete params.qualityReviewTime;
            delete params.status;
            delete params.workshopReviewResult;
            delete params.qualityReviewResult;
            await updateMeetingDetail(params);
          }
          this.airList = await getMeetingList(this.searchForm);
          this.airDialogVisible = false;
        }
      });
    },
    productFormSubmit2() {
      this.$refs.productFormRef.validate(async (valid) => {
        this.airDetailForm.id = this.airForm.id;
        if (valid) {
          if (this.handleDetailType === "add") {
            console.log(this.airDetailForm);
            await recordingMeetingList(this.airDetailForm);
            this.airForm.purpose = this.airDetailForm.purpose;
            this.airForm.recordContent = this.airDetailForm.recordContent;
          }
          this.innerVisible = false;
        }
      });
    },
    productFormSubmit3() {
      this.$refs.productFormRef.validate(async (valid) => {
        if (valid) {
          this.airForm.signTime = this.formatDate();
          let jsonArr = this.airForm.employeeId.map((item) => {
            return {
              meetingId: this.airForm.meetingId,
              employeeId: item,
              signTime: this.airForm.signTime,
            };
          });
          let params = {
            id: this.airForm.meetingId,
            signJson: JSON.stringify(jsonArr),
          };
          console.log(params, "params");
          await signMeetingList(params);
          this.innerVisible2 = false;
        }
      });
    },
    handleAdd() {
      this.airFormTitle = "新增培训会议信息";
      this.handleType = "add";
      this.airDialogVisible = true;
    },
    handleAdd2() {
      this.handleDetailType = "add";
      this.innerVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteMeetingList(row.id);
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.airList = await getMeetingList(this.searchForm);
      });
    },
    handleDelete2(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        console.log(row, "row");
        console.log(index, "index");
        row.splice(index, 1);
      });
    },
    handleUpdate(index, row) {
      this.airFormTitle = "培训/会议信息";
      this.handleType = "update";
      getMeetingDetail(row.id).then(async (res) => {
        console.log(res);
        await this.getSignList(row.id);
        this.airForm = { ...res };
        console.log(this.airForm);
        this.airDialogVisible = true;
      });
    },
    handleInfo(index, row) {
      this.airFormTitle = "培训/会议信息";
      this.handleType = "detail";
      getMeetingDetail(row.id).then(async (res) => {
        await this.getSignList(row.id);
        this.airForm = { ...res };
        console.log(this.airForm);
        this.airDialogVisible = true;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      getMeetingList(this.searchForm).then((res) => {
        console.log(res, "res");
        this.airList = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      getMeetingList(this.searchForm).then((res) => {
        this.airList = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      getMeetingList(this.searchForm).then((res) => {
        this.airList = res;
      });
    },
    handleInfo2(index, row) {
      this.handleDetailType = "detail";
      let airDetailForm = this.airDetailForm;
      airDetailForm = { ...row };
      airDetailForm.inputType = "detail";
      this.airDetailForm = airDetailForm;
      this.innerVisible = true;
    },
    handleUpdate2(index, row) {
      this.handleDetailType = "update";
      let airDetailForm = this.airDetailForm;
      airDetailForm = { ...row };
      airDetailForm.index = index;
      airDetailForm.inputType = "update";
      this.airDetailForm = airDetailForm;
      this.innerVisible = true;
    },
    handleSign(row) {
      console.log(row, "row");
      this.airForm.meetingId = row.id;
      this.innerVisible2 = true;
    },
    EmployeeChange(value) {
      console.log(value, "value");
      if (value) {
        this.airForm.employeeId = value;
      } else {
        this.airForm.employeeId = "";
      }
    },
    //inputTime 参数是毫秒级时间戳
    formatDate() {
      var date = new Date();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
  },

  watch: {
    runningTime(val, oldVal) {
      this.airForm.startupTime = val ? val[0] : "";
      this.airForm.offTime = val ? val[1] : "";
    },
    airDialogVisible(val) {
      if (!val) {
        this.airForm = {};
        this.runningTime = [];
        this.signList = [];
      }
    },
    innerVisible(val) {
      if (!val) {
        this.airDetailForm = {};
        this.signList = [];
      }
    },
    innerVisible2(val) {
      if (!val) {
        this.airForm = {};
        this.signList = [];
      }
    },
  },
};
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
